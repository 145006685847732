import React from 'react'
import { Avatar } from 'baseui/avatar'

const ProfileAvatar = props => {
  return (
    <Avatar
      name={props.name}
      size={props.size}
      overrides={{
        Avatar: {
          style: ({ $theme }) => (props.square && {
            borderTopLeftRadius: $theme.borders.radius100,
            borderTopRightRadius: $theme.borders.radius100,
            borderBottomRightRadius: $theme.borders.radius100,
            borderBottomLeftRadius: $theme.borders.radius100,
          }),
        },
        Root: {
          style: ({ $theme }) => (props.square && {
            borderTopLeftRadius: $theme.borders.radius100,
            borderTopRightRadius: $theme.borders.radius100,
            borderBottomRightRadius: $theme.borders.radius100,
            borderBottomLeftRadius: $theme.borders.radius100,
          }),
        },
        Initials: {
          style: ({ $theme }) => (props.invert && {
              color: $theme.colors.primary,
              backgroundColor: $theme.colors.white
            }
          )
        }
      }}
      src={props.src}
      key={props.size}
    />
  )
}

ProfileAvatar.propTypes = {}

export default ProfileAvatar