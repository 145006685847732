import React from "react"
import { useEffect } from "react"
import get from "lodash/get"
import { navigate } from "gatsby"
import { Block } from "baseui/block"
import { Spinner } from "baseui/spinner"

const CheckPermission = (roles, WrappedComponent) => {
  const WrapWithPermission = props => {
    useEffect(() => {
      const userRole = get(props.userState, "user.role")
      if (userRole && !roles.includes(userRole)) {
        return navigate("/")
      }
    }, [props.userState])
    if (get(props, "userState.user.role")) {
      return <WrappedComponent {...props} />
    } else {
      return (
        <Block
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
          background={"rgba(255, 255, 255, 0.87)"}
        >
          <Spinner
            size={80}
            overrides={{
              ActivePath: {
                style: ({ $theme }) => ({ fill: $theme.colors.primary }),
              },
            }}
          />
        </Block>
      )
    }
  }
  return WrapWithPermission
}

export default CheckPermission
