import React from 'react';
import { Store } from '../store';

const WithUser = (WrappedComponent) => {
	const WrappedComponentWithRedux = (props) => {
		const data = React.useContext(Store);
		const [ state, dispatch ] = data.user;
		return <WrappedComponent {...props} userState={state} userDispatch={dispatch} />;
	};
	return WrappedComponentWithRedux;
};

export default WithUser;
